import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNextState,
  changePreviousState,
  // popupActive,
  loaderActive,
  loaderInActive,
  // popupVariety,
  applicationDataPartA,
  applicationDataPartB,
  applicationDataPartC,
  applicationDataPartD,
  applicationDataPartE,
  applicationDataPartF,
  applicationDataAttachment,
} from "../../../../../../redux/actions";
import { toast } from "react-toastify";
import useHttp from "../../../../../../Http/useHttp";
import { useAuth } from "../../../../../../Authentication/AuthContext";

import { useTranslation } from "react-i18next";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
// Custom Style
import styles from "./StepperShift.module.css";

export default function StepperShift({
  backDisabled = false,
  nextDisabled = false,
  increaseBy = 1,
  decreaseBy = 1,
  lastCompleted = "A",
  showDraft = false,
  endPoint = "update",
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const applicationData = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const {
    partA: {
      existingPassNumber = "",
      gdNumber = "",
      gdEntry = "",
      psName = "",
      issueType = "",
      orgId = "",
      siteId = "",
      passTypeId = "",
      passType = "",
    } = {},
  } = applicationData || {};

  const validateReIssueFormData = () => {
    if (endPoint !== "reissue") return;
    let missingFields = [];

    // Check required fields based on issueType
    if (!existingPassNumber) {
      missingFields.push("Existing Pass Number");
    }

    if (passType === "ASP" && !siteId) {
      missingFields.push("Airport");
    }

    if (issueType === "Re-issue (Lost /Stolen)") {
      if (!gdNumber) {
        missingFields.push("GD Number");
      }
      if (!gdEntry) {
        missingFields.push("GD Entry Date");
      }
      if (!psName) {
        missingFields.push("PS Name");
      }
    }

    // If there are missing fields, show a toast and return false
    if (missingFields.length > 0) {
      toast.warn("Please provide: " + missingFields.join(", "), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    // If validation passes, return the formData
    return {
      existingPassNumber,
      gdNumber,
      gdEntry,
      psName,
      issueType,
      orgId,
      siteId,
      passTypeId,
      passType,
    };
  };

  // console.log(applicationData);
  const api = useHttp("authorized");
  const { logout } = useAuth();

  const handlePrev = () => {
    if (!backDisabled) dispatch(changePreviousState(decreaseBy));
    else return;
  };

  const reduxUpdateDecision = (endpoint, response) => {
    switch (endpoint) {
      case "validate":
        dispatch(changeNextState(increaseBy));
        return;

      case "reissue":
        // console.log(response);
        dispatch(
          applicationDataPartA({
            ...response.draft.part_a,
            completed: lastCompleted,
          })
        );
        dispatch(applicationDataPartB(response.draft.part_b));
        dispatch(applicationDataPartC(response.draft.part_c));
        dispatch(applicationDataPartD(response.draft.part_d));
        dispatch(applicationDataPartE(response.draft.part_e));
        dispatch(applicationDataPartF(response.draft.part_f));
        dispatch(applicationDataAttachment(response.draft.attachments));
        dispatch(changeNextState(increaseBy));
        return;

      default:
        dispatch(
          applicationDataPartA({
            ...response.draft[0].part_a,
            completed: lastCompleted,
          })
        );
        dispatch(applicationDataPartB(response.draft[0].part_b));
        dispatch(applicationDataPartC(response.draft[0].part_c));
        dispatch(applicationDataPartD(response.draft[0].part_d));
        dispatch(applicationDataPartE(response.draft[0].part_e));
        dispatch(applicationDataPartF(response.draft[0].part_f));
        dispatch(applicationDataAttachment(response.draft[0].attachments));
        dispatch(changeNextState(increaseBy));
        return;
    }
  };

  const handleNext = async () => {
    if (showDraft) {
      const reIssueFormData = validateReIssueFormData();
      if (endPoint === "reissue" && !reIssueFormData) return;

      dispatch(loaderActive());
      // console.log(applicationData);

      // if (endPoint === "validate") {
      //   toast.info("Validating Your Information . . .", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: false,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // }

      // if (endPoint === "reissue") {
      //   toast.info("Fetching Your Information . . .", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: false,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // }

      try {
        const resp = await api.post(
          `draft/${endPoint}`,
          endPoint === "reissue" ? reIssueFormData : applicationData
        );
        // console.log(resp.data);
        reduxUpdateDecision(endPoint, resp.data);
        dispatch(loaderInActive());
      } catch (error) {
        dispatch(loaderInActive());
        console.log(error.response);
        if (error.response.data.status === 401) logout();

        if (error.response.status === 500 || error.response.status === 404) {
          return toast.warn(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        if (!error.response.data.errors) return;

        let allErrors = error.response.data.errors;
        let errorMsg = "";

        if (Object.keys(allErrors).length > 0) {
          Object.keys(allErrors).map((errorKey) => {
            if (allErrors[errorKey].length > 0) {
              errorMsg = allErrors[errorKey][0];
              toast.warn("Data not saved: " + errorMsg, {
                // toast.warn("Provide All Required Data Before Submission", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.warn("Data not saved: Unknown Error", {
                // toast.warn("Provide All Required Data Before Submission", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        } else {
          toast.warn("Data not saved: " + error.response.data.message, {
            // toast.warn("Provide All Required Data Before Submission", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      !nextDisabled && dispatch(changeNextState(increaseBy));
    }
  };

  return (
    <ul className={styles.progressFlow}>
      <li className={styles.item} onClick={() => handlePrev()}>
        <button
          className={`btn ${styles.button} btn-primary ${
            backDisabled ? "disabled" : null
          }`}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" />{" "}
          {t("NEWAPPLICATION.backBtn")}
        </button>
      </li>
      {/* <li className="progress-flow-item text-center active">
                <FontAwesomeIcon icon={faCircle}/>
            </li>
            <li className="progress-flow-item text-center">
                <FontAwesomeIcon icon={faCircle}/>
            </li>
            <li className="progress-flow-item text-center">
                <FontAwesomeIcon icon={faCircle}/>
            </li>
            <li className="progress-flow-item text-center">
                <FontAwesomeIcon icon={faCircle}/>
            </li>
            <li className="progress-flow-item text-center">
                <FontAwesomeIcon icon={faCircle}/>
            </li>
            <li className="progress-flow-item text-center">
                <FontAwesomeIcon icon={faCircle}/>
            </li> */}
      <li className={styles.item} onClick={() => handleNext()}>
        <button
          className={`btn ${styles.button} btn-primary ${
            nextDisabled ? "disabled" : null
          }`}
        >
          {/* <a
          href="e"
          className={`btn custom-btn btn-primary ${
            nextDisabled ? "disabled" : null
          }`}
        > */}
          {t("NEWAPPLICATION.nextBtn")}{" "}
          <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
          {/* {nextDisabled ? "Finish" : "Next"} 
          {nextDisabled ? (
            ""
          ) : (
            <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
          )}*/}
        </button>
      </li>
    </ul>
  );
}
