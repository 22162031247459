import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveApplicationPart,
  applicationDataPartD,
} from "../../../../../../redux/actions";

// bootstraps
import { Accordion } from "react-bootstrap";
import { toast } from "react-toastify";

import FormTitle from "../../Utilities/FormTitle/FormTitle";
import Stepper from "../../Utilities/Stepper/Stepper";
import StepperShift from "../../Utilities/StepperShift/StepperShift";
import ImageUpload from "../../../../../../Utilities/ImageUpload/ImageUpload";
import Attachments from "../../../../../../Utilities/Attachments/Attachments";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

// custom Style
import styles from "./ApplicantsDeclaration.module.css";

const ApplicantsDeclaration = () => {
  const [checkedValue, setCheckedValue] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorLog, setErrorLog] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const passType = useSelector(
    (state) => state.newApplicationData.newApplication.partA.passType
  );

  const draftId = useSelector(
    (state) => state.newApplicationData.newApplication.partA.draftId
  );

  const signature = useSelector(
    (state) => state.newApplicationData.newApplication.partD.signature
  );

  const applicationData = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const applicantImage = useSelector(
    (state) => state.newApplicationData.newApplication.partB.profilePic
  );

  const forwardingLtr = applicationData.attachments.forwardingLetter;
  const nidCopy = applicationData.attachments.copyOfNID;
  const pvFile = applicationData.partB.policeVerificationFile;
  const dutyDescriptionFile = applicationData.partC.natureOfDutyDescriptionFile;
  const pvValidity = applicationData.partB.policeVerificationValidTill;
  const attGrp = applicationData.attachments.selectedAttachmentGroupASP;

  useEffect(() => {
    // signature ? setCheckedValue(true) : setCheckedValue(false);
    //GovtEmp, NonGovtEmp, Foreigners

    if (passType === "ASP" && attGrp === "GovtEmp") {
      applicantImage && signature && nidCopy
        ? setCheckedValue(true)
        : setCheckedValue(false);
    }

    if (passType === "ASP" && attGrp === "NonGovtEmp") {
      applicantImage && signature && pvFile && pvValidity && nidCopy
        ? setCheckedValue(true)
        : setCheckedValue(false);
    }

    if (passType === "ASP" && attGrp === "Foreigners") {
      applicantImage && signature
        ? setCheckedValue(true)
        : setCheckedValue(false);
    }

    if (passType === "CMAP") {
      applicantImage && signature && nidCopy
        ? setCheckedValue(true)
        : setCheckedValue(false);
    }

    if (passType === "NAP") {
      applicantImage && signature && forwardingLtr && nidCopy
        ? setCheckedValue(true)
        : setCheckedValue(false);
    }

    if (passType === "AAIAP") {
      applicantImage && signature && dutyDescriptionFile && nidCopy
        ? setCheckedValue(true)
        : setCheckedValue(false);
    }

    if (passType === "NIAC") {
      applicantImage && signature && nidCopy
        ? setCheckedValue(true)
        : setCheckedValue(false);
    }

    switch (passType) {
      case "ASP":
        dispatch(changeActiveApplicationPart("Part E"));
        break;
      case "CMAP":
        dispatch(changeActiveApplicationPart("Part E"));
        break;

      default:
        dispatch(changeActiveApplicationPart("Part D"));
        break;
    }
  }, [
    passType,
    dispatch,
    signature,
    applicationData,
    applicantImage,
    forwardingLtr,
    nidCopy,
    pvFile,
    pvValidity,
    attGrp,
  ]);

  useEffect(() => {
    toast.info(
      "Please Attach Color PDF Of Your Existing Airport Pass, Where Applicable.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  const textUploadSignature = (
    <>
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.upload")}{" "}
      <span className={styles.textBold}>
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.jpg")}
      </span>{" "}
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.or")}{" "}
      <span className={styles.textBold}>
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.png")}
      </span>{" "}
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.file")}{" "}
      <span className={styles.textBold}>
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.size")}.
      </span>
    </>
  );

  const allErrors = (err) => {
    // console.log(err);
    if (err.errors) {
      const keys = Object.keys(err.errors);
      // console.log(keys);
      let errorDataLog = [];
      keys.map((key) =>
        err.errors[key].map((data) => (errorDataLog = [...errorDataLog, data]))
      );
      console.log(errorDataLog);
      setErrorLog(errorDataLog);
      setIsError(true);
    } else {
      toast.warn(err.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // const willNextStepBeDisabled = () => {
  //   if (passType === "ASP") {
  //     if (uploadCategory) return false;
  //     else return true;
  //   } else return false;
  // };

  const nextStepDecider = () => {
    switch (passType) {
      case "ASP":
        return (
          <StepperShift
            errors={allErrors}
            showDraft
            // lastCompleted="D"
            endPoint={"validate"}
            // nextDisabled={willNextStepBeDisabled()}
          />
        );
      case "CMAP":
        return (
          <StepperShift
            errors={allErrors}
            showDraft
            // lastCompleted="D"
            endPoint={"validate"}
            // nextDisabled={willNextStepBeDisabled()}
          />
        );

      default:
        return (
          <StepperShift
            errors={allErrors}
            showDraft
            // lastCompleted="D"
            endPoint={"validate"}
            decreaseBy={2}
            // nextDisabled={willNextStepBeDisabled()}
          />
        );
    }
  };

  const previousStepDecider = () => {
    switch (passType) {
      case "ASP":
        return <StepperShift nextDisabled />;
      case "CMAP":
        return <StepperShift nextDisabled />;

      default:
        return <StepperShift decreaseBy={2} nextDisabled />;
    }
  };

  const ErrorAccordion = () => (
    <>
      <Accordion className={`${styles.errorLog} ${styles.show}`}>
        <Accordion.Item>
          <Accordion.Header>Error Log</Accordion.Header>
          <Accordion.Body className={styles.accordionBody}>
            {errorLog.length > 0 &&
              errorLog.map((error, index) => (
                <div className={styles.errorLogItem} key={index}>
                  <div className={styles.errorLogItemLeft}>
                    <div className={styles.errorLogIconContain}>
                      <FontAwesomeIcon
                        className={styles.errorIcon}
                        icon={faExclamation}
                      />
                    </div>
                  </div>
                  <div className={styles.errorLogItemRight}>
                    <p className={styles.errorLogItemHeader}>{error}</p>
                  </div>
                </div>
              ))}

            {/* <div className={styles.errorLogItem}>
              <div className={styles.errorLogItemLeft}>
                <div className={styles.errorLogIconContain}>
                  <FontAwesomeIcon
                    className={styles.errorIcon}
                    icon={faExclamation}
                  />
                </div>
              </div>
              <div className={styles.errorLogItemRight}>
                <p className={styles.errorLogItemHeader}>User Name Missing.</p>
              </div>
            </div> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );

  const signatureUpload = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataPartD({
          signature: url,
        })
      );
    } else {
      dispatch(
        applicationDataPartD({
          signature: "",
        })
      );
    }
  };

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.applicantsDeclaration.title")}`}
        subTitle={`${t("NEWAPPLICATION.applicantsDeclaration.subTitle")}`}
      />
      <Stepper />
      {/* Error Log */}
      {isError && <ErrorAccordion />}

      <div className="row">
        {/* body Content */}
        <div className="col-12 mt-3">
          <div className={`col-12 ${styles.marginHeader} mb-3`}>
            <h4 className={styles.headerAttachment}>
              Upload Required Documents &amp; Attachments for {passType}:
            </h4>
          </div>

          <Attachments mode="write" />

          <div className="mt-4 mb-2">
            <h5 className={styles.headerText}>
              {t("NEWAPPLICATION.applicantsDeclaration.headerText.text")}{" "}
              {passType}:
            </h5>

            <ul className="p-0 ps-3">
              <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                {t("NEWAPPLICATION.applicantsDeclaration.textList.item1")}
              </li>
              <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                {t("NEWAPPLICATION.applicantsDeclaration.textList.item2.text")}{" "}
                {passType === "NIAC"
                  ? `${t(
                      "NEWAPPLICATION.applicantsDeclaration.textList.item2.textInspector"
                    )}`
                  : `${t(
                      "NEWAPPLICATION.applicantsDeclaration.textList.item2.textPurposes"
                    )}`}
              </li>
              <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                {t("NEWAPPLICATION.applicantsDeclaration.textList.item3")}
              </li>
              <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                {t("NEWAPPLICATION.applicantsDeclaration.textList.item4")}
              </li>
              <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                {t("NEWAPPLICATION.applicantsDeclaration.textList.item5")}
              </li>
              <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                {t("NEWAPPLICATION.applicantsDeclaration.textList.item6")}
              </li>
              <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                {t("NEWAPPLICATION.applicantsDeclaration.textList.item7")}
              </li>
            </ul>
          </div>
        </div>

        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              {t("NEWAPPLICATION.applicantsDeclaration.uploadSignature.label")}
            </label>
            <span className="text-danger">*</span>
            {/* <ImageUploader
              statusUpload={false}
              textAdvise={textUploadSignature}
              statusCheckbox={false}
              checkText={null}
              applicationPart={"partD"}
              saveTo={"signature"}
            /> */}
            <ImageUpload
              // instruction="Upload A Single .jpg or .png File Should Not Be More Than 5MB And The Picture Dimension should be 25mm X 30mm."
              instruction={textUploadSignature}
              execute={signatureUpload}
              endpoint="draft/upload/signature"
              formData={{ draftId: draftId, signature: "" }}
              dataKey="signature"
              defaultValue={signature}
            />
            {/* <FileUploader
              statusUpload={false}
              textAdvise={textUploadSignature}
              statusCheckbox={false}
              checkText={null}
            /> */}
          </div>
        </div>
      </div>
      {/* <StepperShift /> */}
      {checkedValue ? nextStepDecider() : previousStepDecider()}
    </>
  );
};

export default ApplicantsDeclaration;
