import React, { useState, useEffect, useRef } from "react";
import {
  applicationDataAttachment,
  applicationDataAttachmentReset,
  applicationDataPartB,
  applicationDataPartC,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
// import PDFAttachment from "../../Utilities/PDFAttachment/PDFAttachment";
import ImageUpload from "../../Utilities/ImageUpload/ImageUpload";
import PDFUpload from "../../Utilities/PDFUpload/PDFUpload";
import Selector from "../../Utilities/Selector/Selector";
import DateInput from "../../Utilities/DateInput/DateInput";
import InputField from "../../Utilities/InputField/InputField";
import userImage from "../../assets/images/defaultUser.jpg";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArchive,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";

// custom Style
import styles from "./Attachments.module.css";

function Attachments({ mode }) {
  const [uploadCategory, setUploadCategory] = useState("");
  const categorySelection = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const draftId = myApplication.partA.draftId;
  // const appId = myApplication.partA.applicationId; myApplication.partA.issueType

  const card = myApplication.partA.passType;
  const aspUploadCategory =
    myApplication.attachments.selectedAttachmentGroupASP;

  const textUpload = (
    <>
      Upload A Single <strong>.pdf</strong> File. The File Should Not Be More
      Than 5MB.
    </>
  );

  const textUploadSignature = (
    <>
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.upload")}{" "}
      <span className="textBold_attachment">
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.jpg")}
      </span>{" "}
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.or")}{" "}
      <span className="textBold_attachment">
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.png")}
      </span>{" "}
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.file")}{" "}
      <span className="textBold_attachment">
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.size")}.
      </span>
    </>
  );

  const uploadCategories = [
    {
      label: `Select your category`,
      value: "",
    },
    {
      label: `Bangladeshi Govt Employee`,
      value: "GovtEmp",
    },
    {
      label: `Bangladeshi Citizen Other Than (Govt Employee)`,
      value: "NonGovtEmp",
    },
    {
      label: `Foreigners`,
      value: "Foreigners",
    },
  ];

  const scrollToRef = (elem) => {
    // let position = elem.current.offsetTop + 200;
    // console.log(elem.current.offsetTop, position);

    elem.current?.scrollIntoView({
      behavior: "smooth",
    });

    // window.scrollTo({
    //   top: elem.current.offsetTop,
    //   behavior: "smooth",
    // });
  };

  const selectCategory = (category) => {
    scrollToRef(categorySelection);
    dispatch(applicationDataAttachmentReset());
    setUploadCategory(category);
    dispatch(
      applicationDataAttachment({
        selectedAttachmentGroupASP: category,
      })
    );
  };

  const policeVerification = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataPartB({
          policeVerificationFile: url,
        })
      );
    } else {
      dispatch(
        applicationDataPartB({
          policeVerificationFile: "",
          policeVerificationValidTill: "",
        })
      );
    }
  };

  const policeVerificationValidTill = (inputData) => {
    dispatch(
      applicationDataPartB({
        policeVerificationValidTill: inputData,
      })
    );
  };

  const gsatValidTill = (inputData) => {
    dispatch(
      applicationDataPartB({
        gsatValidTill: inputData,
      })
    );
  };

  const GSAT = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataPartB({
          gsatFile: url,
        })
      );
    } else {
      dispatch(
        applicationDataPartB({
          gsatFile: "",
          gsatValidTill: "",
        })
      );
    }
  };

  const profilePic = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataPartB({
          profilePic: url,
        })
      );
    } else {
      dispatch(
        applicationDataPartB({
          profilePic: "",
        })
      );
    }
  };

  const securityClearanceMOHA = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          securityClearanceMOHA: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          securityClearanceMOHA: "",
        })
      );
    }
  };

  const recommendationBIDA = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          recommendationBIDA: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          recommendationBIDA: "",
        })
      );
    }
  };

  const recommendationOfMinistry = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          recommendationOfMinistry: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          recommendationOfMinistry: "",
        })
      );
    }
  };

  const noteVerbale = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          noteVerbale: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          noteVerbale: "",
        })
      );
    }
  };

  const recommendationMOFA = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          recommendationMOFA: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          recommendationMOFA: "",
        })
      );
    }
  };

  const employeeId = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          employeeId: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          employeeId: "",
        })
      );
    }
  };

  const employeeLicense = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          employeeLicense: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          employeeLicense: "",
        })
      );
    }
  };

  const certificateFSR = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          certificateFSR: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          certificateFSR: "",
        })
      );
    }
  };

  const validAOC = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          validAOC: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          validAOC: "",
        })
      );
    }
  };

  const backgroundCheck = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          backgroundCheck: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          backgroundCheck: "",
        })
      );
    }
  };

  const forwardingLetter = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          forwardingLetter: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          forwardingLetter: "",
        })
      );
    }
  };

  const copyOfPassport = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          copyOfPassport: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          copyOfPassport: "",
        })
      );
    }
  };

  const copyOfVisa = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          copyOfVisa: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          copyOfVisa: "",
        })
      );
    }
  };

  const copyOfNID = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          copyOfNID: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          copyOfNID: "",
        })
      );
    }
  };

  const natureOfDutyDescriptionFile = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataPartC({
          natureOfDutyDescriptionFile: url,
        })
      );
    } else {
      dispatch(
        applicationDataPartC({
          natureOfDutyDescriptionFile: "",
        })
      );
    }
  };

  const typeOfAuthorizationSafetyFile = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataPartC({
          typeOfAuthorizationSafetyFile: url,
        })
      );
    } else {
      dispatch(
        applicationDataPartC({
          typeOfAuthorizationSafetyFile: "",
        })
      );
    }
  };

  const officeOrder = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          officeOrder: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          officeOrder: "",
        })
      );
    }
  };

  const copyOfWorkOrder = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          copyOfWorkOrder: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          copyOfWorkOrder: "",
        })
      );
    }
  };

  const copyOfAgreement = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          copyOfAgreement: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          copyOfAgreement: "",
        })
      );
    }
  };

  const drivingLicense = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          drivingLicense: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          drivingLicense: "",
        })
      );
    }
  };

  const existingPermit = (url) => {
    // console.log(url);
    if (url) {
      dispatch(
        applicationDataAttachment({
          existingPermit: url,
        })
      );
    } else {
      dispatch(
        applicationDataAttachment({
          existingPermit: "",
        })
      );
    }
  };

  const GovtEmp = () => {
    return (
      <>
        {/* ====================== Picture ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Upload Picture<span className="text-danger">*</span>
            </label>

            <ImageUpload
              instruction={textUploadSignature}
              execute={profilePic}
              endpoint="draft/upload/image"
              formData={{ draftId, profilePic: "" }}
              dataKey="profilePic"
              defaultValue={myApplication.partB.profilePic}
            />
          </div>
        </div>

        {/* ====================== Forwarding Letter From Employer ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Forwarding letter from the employer authorized official with
              confirmation statement that the initial background checks and the
              police verification checks has been conducted on the person.
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={forwardingLetter}
              endpoint="draft/upload/forwarding-letter"
              formData={{ draftId, forwardingLetter: "" }}
              dataKey="forwardingLetter"
              fileName="Forwarding Letter"
              defaultValue={myApplication.attachments.forwardingLetter}
            />
          </div>
        </div>

        {/* ====================== Copy Of NID ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of National Id Card<span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={copyOfNID}
              endpoint="draft/upload/nid-copy"
              formData={{ draftId, copyOfNID: "" }}
              dataKey="copyOfNID"
              fileName="NID Card"
              defaultValue={myApplication.attachments.copyOfNID}
            />
          </div>
        </div>

        {/* ====================== Employment Id ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Employment Id
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={employeeId}
              endpoint="draft/upload/employee-id"
              formData={{ draftId, employeeId: "" }}
              dataKey="employeeId"
              fileName="Copy of Employee Id"
              defaultValue={myApplication.attachments.employeeId}
            />
          </div>
        </div>

        {/* ====================== Posting Order ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Posting order/ office order for the job assignment at airport
              (initial application)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={officeOrder}
              endpoint="draft/upload/office-order"
              formData={{ draftId, officeOrder: "" }}
              dataKey="officeOrder"
              fileName="Posting order/ office order"
              defaultValue={myApplication.attachments.officeOrder}
            />
          </div>
        </div>

        {/* ====================== Driving License ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Driving License (Where Applicable)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={drivingLicense}
              endpoint="draft/upload/driving-license"
              formData={{ draftId, drivingLicense: "" }}
              dataKey="drivingLicense"
              fileName="Copy of Driving License"
              defaultValue={myApplication.attachments.drivingLicense}
            />
          </div>
        </div>

        {/* ====================== Existing ID Card ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Existing Pass Card (In case of Renewal)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={existingPermit}
              endpoint="draft/upload/existing-permit"
              formData={{ draftId, existingPermit: "" }}
              dataKey="existingPermit"
              fileName="Copy of Existing Pass Card"
              defaultValue={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        {/* ====================== GSAT ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              GSAT Certificate
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={GSAT}
              endpoint="draft/upload/gsat"
              formData={{ draftId, gsat: "" }}
              dataKey="gsat"
              fileName="GSAT File"
              defaultValue={myApplication.partB.gsatFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`GSAT ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={gsatValidTill}
            defaultValue={
              myApplication.partB.gsatValidTill
                ? myApplication.partB.gsatValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>
      </>
    );
  };

  const NonGovtEmp = () => {
    return (
      <>
        {/* ====================== Picture ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Upload Picture<span className="text-danger">*</span>
            </label>

            <ImageUpload
              instruction={textUploadSignature}
              execute={profilePic}
              endpoint="draft/upload/image"
              formData={{ draftId, profilePic: "" }}
              dataKey="profilePic"
              defaultValue={myApplication.partB.profilePic}
            />
          </div>
        </div>

        {/* ====================== Forwarding Letter From Employer ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Forwarding letter from the employer
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={forwardingLetter}
              endpoint="draft/upload/forwarding-letter"
              formData={{ draftId, forwardingLetter: "" }}
              dataKey="forwardingLetter"
              fileName="Forwarding Letter"
              defaultValue={myApplication.attachments.forwardingLetter}
            />
          </div>
        </div>

        {/* ====================== Background Check By Employer ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Completed Background Check By Employer
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={backgroundCheck}
              endpoint="draft/upload/background-check"
              formData={{ draftId, backgroundCheck: "" }}
              dataKey="backgroundCheck"
              fileName="Background Check"
              defaultValue={myApplication.attachments.backgroundCheck}
            />
          </div>
        </div>

        {/* ====================== Police Verification  ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Police Verification Report From Special Branch of Bangladesh
              Police / Completed Police Verification Form
              <span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={policeVerification}
              endpoint="draft/upload/police-verification"
              formData={{ draftId, policeVerification: "" }}
              dataKey="policeVerification"
              fileName="Police Verification File"
              defaultValue={myApplication.partB.policeVerificationFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`Police Verification ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={policeVerificationValidTill}
            defaultValue={
              myApplication.partB.policeVerificationValidTill
                ? myApplication.partB.policeVerificationValidTill
                : ""
            }
          />
        </div>

        {/* ====================== Copy Of NID ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of National Id Card<span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={copyOfNID}
              endpoint="draft/upload/nid-copy"
              formData={{ draftId, copyOfNID: "" }}
              dataKey="copyOfNID"
              fileName="NID Card"
              defaultValue={myApplication.attachments.copyOfNID}
            />
          </div>
        </div>

        {/* ====================== Employment Id ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Employment Id
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={employeeId}
              endpoint="draft/upload/employee-id"
              formData={{ draftId, employeeId: "" }}
              dataKey="employeeId"
              fileName="Copy of Employee Id"
              defaultValue={myApplication.attachments.employeeId}
            />
          </div>
        </div>

        {/* ====================== AOC / Permission / Flight Schedule ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Valid AOC/ Permission and/ or Flight Schedule (Where
              Applicable) that is approved by CAAB
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={validAOC}
              endpoint="draft/upload/valid-aoc"
              formData={{ draftId, validAOC: "" }}
              dataKey="validAOC"
              fileName="Valid AOC/ Permission and/ or Flight Schedule"
              defaultValue={myApplication.attachments.validAOC}
            />
          </div>
        </div>

        {/* ====================== Agreement ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Agreement with CAAB or other Organization (Where
              Applicable)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={copyOfAgreement}
              endpoint="draft/upload/agreement-copy"
              formData={{ draftId, copyOfAgreement: "" }}
              dataKey="copyOfAgreement"
              fileName="Copy of Agreement"
              defaultValue={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>

        {/* ====================== Work Order ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Work Order (Where Applicable)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={copyOfWorkOrder}
              endpoint="draft/upload/work-order-copy"
              formData={{ draftId, copyOfWorkOrder: "" }}
              dataKey="copyOfWorkOrder"
              fileName="Copy of Work Order"
              defaultValue={myApplication.attachments.copyOfWorkOrder}
            />
          </div>
        </div>

        {/* ====================== Driving License ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Driving License (Where Applicable)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={drivingLicense}
              endpoint="draft/upload/driving-license"
              formData={{ draftId, drivingLicense: "" }}
              dataKey="drivingLicense"
              fileName="Copy of Driving License"
              defaultValue={myApplication.attachments.drivingLicense}
            />
          </div>
        </div>

        {/* ====================== Existing ID Card ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Existing Pass Card (In case of Renewal)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={existingPermit}
              endpoint="draft/upload/existing-permit"
              formData={{ draftId, existingPermit: "" }}
              dataKey="existingPermit"
              fileName="Copy of Existing Pass Card"
              defaultValue={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        {/* ====================== GSAT ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              GSAT Certificate
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={GSAT}
              endpoint="draft/upload/gsat"
              formData={{ draftId, gsat: "" }}
              dataKey="gsat"
              fileName="GSAT File"
              defaultValue={myApplication.partB.gsatFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`GSAT ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={gsatValidTill}
            defaultValue={
              myApplication.partB.gsatValidTill
                ? myApplication.partB.gsatValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>
      </>
    );
  };

  const Foreigners = () => {
    return (
      <>
        {/* ====================== Picture ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Upload Picture<span className="text-danger">*</span>
            </label>

            <ImageUpload
              instruction={textUploadSignature}
              execute={profilePic}
              endpoint="draft/upload/image"
              formData={{ draftId, profilePic: "" }}
              dataKey="profilePic"
              defaultValue={myApplication.partB.profilePic}
            />
          </div>
        </div>

        {/* ====================== Passport ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Passport
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={copyOfPassport}
              endpoint="draft/upload/passport-copy"
              formData={{ draftId, copyOfPassport: "" }}
              dataKey="copyOfPassport"
              fileName="Copy of Passport"
              defaultValue={myApplication.attachments.copyOfPassport}
            />
          </div>
        </div>

        {/* ====================== Visa Card ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>Copy of Visa</label>
            <PDFUpload
              instruction={textUpload}
              execute={copyOfVisa}
              endpoint="draft/upload/visa-copy"
              formData={{ draftId, copyOfVisa: "" }}
              dataKey="copyOfVisa"
              fileName="Copy of Visa"
              defaultValue={myApplication.attachments.copyOfVisa}
            />
          </div>
        </div>

        {/* ====================== Existing ID Card ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Existing Pass Card (In case of Renewal)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={existingPermit}
              endpoint="draft/upload/existing-permit"
              formData={{ draftId, existingPermit: "" }}
              dataKey="existingPermit"
              fileName="Copy of Existing Pass Card"
              defaultValue={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        {/* ====================== GSAT ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              GSAT Certificate
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={GSAT}
              endpoint="draft/upload/gsat"
              formData={{ draftId, gsat: "" }}
              dataKey="gsat"
              fileName="GSAT File"
              defaultValue={myApplication.partB.gsatFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`GSAT ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={gsatValidTill}
            defaultValue={
              myApplication.partB.gsatValidTill
                ? myApplication.partB.gsatValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>

        {/* ====================== Recommendation Of MOFA ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Recommendation of Ministry of Foreign affairs by endorsing the
              application form
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={recommendationMOFA}
              endpoint="draft/upload/recommendation-mofa"
              formData={{ draftId, recommendationMOFA: "" }}
              dataKey="recommendationMOFA"
              fileName="Recommendation of Ministry of Foreign affairs"
              defaultValue={myApplication.attachments.recommendationMOFA}
            />
          </div>
        </div>

        {/* ====================== Note Verbale ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of note verbale issued by the mission
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={noteVerbale}
              endpoint="draft/upload/note-verbale"
              formData={{ draftId, noteVerbale: "" }}
              dataKey="noteVerbale"
              fileName="Copy of note verbale"
              defaultValue={myApplication.attachments.noteVerbale}
            />
          </div>
        </div>

        {/* ====================== Recommendation of concerned ministry ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Recommendation of concerned ministry or authority
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={recommendationOfMinistry}
              endpoint="draft/upload/recommendation-ministry"
              formData={{ draftId, recommendationOfMinistry: "" }}
              dataKey="recommendationOfMinistry"
              fileName="Recommendation of ministry"
              defaultValue={myApplication.attachments.recommendationOfMinistry}
            />
          </div>
        </div>

        {/* ====================== BIDA ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Recommendation from Bangladesh Investment Development Agency
              (BIDA)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={recommendationBIDA}
              endpoint="draft/upload/recommendation-bida"
              formData={{ draftId, recommendationBIDA: "" }}
              dataKey="recommendationBIDA"
              fileName="Recommendation from BIDA"
              defaultValue={myApplication.attachments.recommendationBIDA}
            />
          </div>
        </div>

        {/* ====================== Security Clearance form Ministry of Home Affairs ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Security Clearance form Ministry of Home Affairs
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={securityClearanceMOHA}
              endpoint="draft/upload/security-clearance-moha"
              formData={{ draftId, securityClearanceMOHA: "" }}
              dataKey="securityClearanceMOHA"
              fileName="Security Clearance From Ministry of Home Affairs"
              defaultValue={myApplication.attachments.securityClearanceMOHA}
            />
          </div>
        </div>
      </>
    );
  };

  const ASP = () => {
    return (
      <>
        <div className="mt-4 mb-2">
          (Following are the "Required List of Documents" of different
          categories)
        </div>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className={styles.headerText}>Bangladeshi Govt Employee </h5>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="p-0 ps-3">
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Upload Picture (picture Upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Forwarding letter from the employer authorized official with
                  confirmation statement that the initial background checks and
                  the police verification checks has been conducted on the
                  person (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of NID (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Employee Id (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Posting order/ office order for the job assignment at airport
                  (initial application) (pdf upload)
                </li>

                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of driving license (Where Applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of existing permit in case of renewal (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Certificate (where applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Valid Till (calendar date)
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className={styles.headerText}>
                Bangladeshi Citizen Other Than Govt Employee
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="p-0 ps-3">
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Upload Picture (picture Upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Forwarding letter from the employer (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Completed Background Check by Employer (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Police verification report form special branch of Bangladesh
                  Police / Completed Police verification Form (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  PV valid till (calendar date)
                </li>

                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of NID (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Employee Id (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Valid AOC/ Permission and/ or Flight Schedule (Where
                  Applicable) that is approved by CAAB (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Agreement with CAAB or other Organization (Where
                  Applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Work Order (Where Applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of driving license (Where Applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of existing permit in case of renewal (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Certificate (where applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Valid Till (calendar date)
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h5 className={styles.headerText}>Foreigners</h5>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="p-0 ps-3">
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Upload Picture (picture Upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Passport (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Visa (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of existing permit in case of renewal (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Certificate (where applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Valid Till (calendar date)
                </li>
              </ul>

              <div className="mt-4 mb-2">
                <h5 className={styles.headerText}>
                  Additional documents for employees of embassies and foreign
                  mission
                </h5>
                <ul className="p-0 ps-3">
                  <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                    Recommendation of Ministry of Foreign affairs by endorsing
                    the application form (pdf upload)
                  </li>
                  <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                    Copy of note verbale issued by the mission (pdf upload)
                  </li>
                </ul>
              </div>

              <div className="mt-4 mb-2">
                <h5 className={styles.headerText}>
                  Additional documents for employees of other international
                  organization and development partners
                </h5>
                <ul className="p-0 ps-3">
                  <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                    Recommendation of concerned ministry or authority (pdf
                    upload)
                  </li>
                </ul>
              </div>

              <div className="mt-4 mb-2">
                <h5 className={styles.headerText}>
                  Additional documents for foreigners working in Bangladesh
                  under work permit
                </h5>
                <ul className="p-0 ps-3">
                  <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                    Recommendation from Bangladesh Investment Development Agency
                    (BIDA) (pdf upload)
                  </li>
                  <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                    Security Clearance form Ministry of Home Affairs (pdf
                    upload)
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>Bangladeshi Govt Employee </h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Upload Picture (picture Upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Forwarding letter from the employer authorized official with
              confirmation statement that the initial background checks and the
              police verification checks has been conducted on the person (pdf
              upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of NID (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Employee Id (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Posting order/ office order for the job assignment at airport
              (initial application) (pdf upload)
            </li>

            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of driving license (Where Applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of existing permit in case of renewal (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Certificate (where applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Valid Till (calendar date)
            </li>
          </ul>
        </div> 
         <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>
            Bangladeshi Citizen Other Than Govt Employee{" "}
          </h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Upload Picture (picture Upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Forwarding letter from the employer (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Completed Background Check by Employer (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Police verification report form special branch of Bangladesh
              Police / Completed Police verification Form (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              PV valid till (calendar date)
            </li>

            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of NID (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Employee Id (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Valid AOC/ Permission and/ or Flight Schedule (Where
              Applicable) that is approved by CAAB (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Agreement with CAAB or other Organization (Where
              Applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Work Order (Where Applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of driving license (Where Applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of existing permit in case of renewal (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Certificate (where applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Valid Till (calendar date)
            </li>
          </ul>
        </div> 
         <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>Foreigners</h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Upload Picture (picture Upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Passport (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Visa (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of existing permit in case of renewal (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Certificate (where applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Valid Till (calendar date)
            </li>
          </ul>
        </div> 

        <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>
            Additional documents for employees of embassies and foreign mission
          </h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Recommendation of Ministry of Foreign affairs by endorsing the
              application form (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of note verbale issued by the mission (pdf upload)
            </li>
          </ul>
        </div>

        <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>
            Additional documents for employees of other international
            organization and development partners
          </h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Recommendation of concerned ministry or authority (pdf upload)
            </li>
          </ul>
        </div>

        <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>
            Additional documents for foreigners working in Bangladesh under work
            permit
          </h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Recommendation from Bangladesh Investment Development Agency
              (BIDA) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Security Clearance form Ministry of Home Affairs (pdf upload)
            </li>
          </ul>
        </div>*/}

        <div className="col-12 mt-3" ref={categorySelection}>
          <Selector
            label="Select Upload Category"
            listItem={uploadCategories}
            defaultValue={
              uploadCategory
                ? uploadCategory
                : aspUploadCategory
                ? aspUploadCategory
                : ""
            }
            execute={(data) => selectCategory(data)}
            disable={!!myApplication.partA.issueType !== "Initial"}
          />
        </div>

        {uploadCategory && (
          <div className={`col-12 ${styles.marginHeader} mb-3`}>
            <h4 className={styles.headerAttachment}>
              Upload Documents According To Your Selected Category:
            </h4>
          </div>
        )}

        {
          <>
            {uploadCategory === "GovtEmp" && <GovtEmp />}
            {uploadCategory === "NonGovtEmp" && <NonGovtEmp />}
            {uploadCategory === "Foreigners" && <Foreigners />}
          </>
        }
      </>
    );
  };

  const CMAP = () => {
    return (
      <>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className={styles.headerText}>
                Following are the Attachments Upload Required For This
                Application
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="p-0 ps-3">
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Upload Picture (picture Upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Police verification file (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  PV valid till (calendar date)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Certificate (where applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Valid Till (calendar date)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Forwarding letter from the employer (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of NID (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Crew Id (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Crew License (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Agreement, in case of Contractual Employment (pdf
                  upload)
                </li>

                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of driving license (Where Applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of existing permit in case of renewal (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Certificate from FSR division (pdf upload)
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>
            Following are the Attachments Upload Required For This Application{" "}
          </h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Upload Picture (picture Upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Police verification file (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              PV valid till (calendar date)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Certificate (where applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Valid Till (calendar date)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Forwarding letter from the employer (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of NID (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Crew Id (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Crew License (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Agreement, in case of Contractual Employment (pdf upload)
            </li>

            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of driving license (Where Applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of existing permit in case of renewal (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Certificate from FSR division (pdf upload)
            </li>
          </ul>
        </div> */}

        {/* ====================== Picture ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Upload Picture<span className="text-danger">*</span>
            </label>

            <ImageUpload
              instruction={textUploadSignature}
              execute={profilePic}
              endpoint="draft/upload/image"
              formData={{ draftId, profilePic: "" }}
              dataKey="profilePic"
              defaultValue={myApplication.partB.profilePic}
            />
          </div>
        </div>

        {/* ====================== Police Verification  ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Police Verification File
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={policeVerification}
              endpoint="draft/upload/police-verification"
              formData={{ draftId, policeVerification: "" }}
              dataKey="policeVerification"
              fileName="Police Verification File"
              defaultValue={myApplication.partB.policeVerificationFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`Police Verification ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={policeVerificationValidTill}
            defaultValue={
              myApplication.partB.policeVerificationValidTill
                ? myApplication.partB.policeVerificationValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>

        {/* ====================== GSAT ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              GSAT Certificate
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={GSAT}
              endpoint="draft/upload/gsat"
              formData={{ draftId, gsat: "" }}
              dataKey="gsat"
              fileName="GSAT File"
              defaultValue={myApplication.partB.gsatFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`GSAT ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={gsatValidTill}
            defaultValue={
              myApplication.partB.gsatValidTill
                ? myApplication.partB.gsatValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>

        {/* ====================== Forwarding Letter From Employer ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Forwarding letter from the employer
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={forwardingLetter}
              endpoint="draft/upload/forwarding-letter"
              formData={{ draftId, forwardingLetter: "" }}
              dataKey="forwardingLetter"
              fileName="Forwarding Letter"
              defaultValue={myApplication.attachments.forwardingLetter}
            />
          </div>
        </div>

        {/* ====================== Copy Of NID ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of National Id Card<span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={copyOfNID}
              endpoint="draft/upload/nid-copy"
              formData={{ draftId, copyOfNID: "" }}
              dataKey="copyOfNID"
              fileName="NID Card"
              defaultValue={myApplication.attachments.copyOfNID}
            />
          </div>
        </div>

        {/* ====================== Crew Id ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Crew Id
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={employeeId}
              endpoint="draft/upload/employee-id"
              formData={{ draftId, employeeId: "" }}
              dataKey="employeeId"
              fileName="Copy of Crew Id"
              defaultValue={myApplication.attachments.employeeId}
            />
          </div>
        </div>

        {/* ====================== Crew License ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Crew License
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={employeeLicense}
              endpoint="draft/upload/employee-license"
              formData={{ draftId, employeeLicense: "" }}
              dataKey="employeeLicense"
              fileName="Copy of Crew License"
              defaultValue={myApplication.attachments.employeeLicense}
            />
          </div>
        </div>

        {/* ====================== Agreement ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Agreement, in case of Contractual Employment
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={copyOfAgreement}
              endpoint="draft/upload/agreement-copy"
              formData={{ draftId, copyOfAgreement: "" }}
              dataKey="copyOfAgreement"
              fileName="Copy of Agreement"
              defaultValue={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>

        {/* ====================== Driving License ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Driving License (Where Applicable)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={drivingLicense}
              endpoint="draft/upload/driving-license"
              formData={{ draftId, drivingLicense: "" }}
              dataKey="drivingLicense"
              fileName="Copy of Driving License"
              defaultValue={myApplication.attachments.drivingLicense}
            />
          </div>
        </div>

        {/* ====================== Existing ID Card ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Existing Pass Card (In case of Renewal)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={existingPermit}
              endpoint="draft/upload/existing-permit"
              formData={{ draftId, existingPermit: "" }}
              dataKey="existingPermit"
              fileName="Copy of Existing Pass Card"
              defaultValue={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        {/* ====================== FSR Certificate ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Certificate from FSR division
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={certificateFSR}
              endpoint="draft/upload/certificate-fsr"
              formData={{ draftId, certificateFSR: "" }}
              dataKey="certificateFSR"
              fileName="Certificate from FSR Division"
              defaultValue={myApplication.attachments.certificateFSR}
            />
          </div>
        </div>
      </>
    );
  };

  const NAP = () => {
    return (
      <>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className={styles.headerText}>
                Following are the Attachments Upload Required For This
                Application
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="p-0 ps-3">
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Upload Picture (picture Upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Forwarding Letter Form concerned division (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Confirmation of Police Verification / Police Verification
                  Report from Special Branch of Bangladesh Police (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  PV valid till (calendar date)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of NID (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Letter of job assignment by CAAB Chairman (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Agreement, in case of Contractual Employment (pdf
                  upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of driving license (Where Applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of existing permit in case of renewal (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Certificate (where applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Valid Till (calendar date)
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>
            Following are the Attachments Upload Required For This Application{" "}
          </h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Upload Picture (picture Upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Forwarding Letter Form concerned division (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Confirmation of Police Verification / Police Verification Report
              from Special Branch of Bangladesh Police (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              PV valid till (calendar date)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of NID (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Letter of job assignment by CAAB Chairman (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Agreement, in case of Contractual Employment (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of driving license (Where Applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of existing permit in case of renewal (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Certificate (where applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Valid Till (calendar date)
            </li>
          </ul>
        </div> */}

        {/* ====================== Picture ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Upload Picture<span className="text-danger">*</span>
            </label>

            <ImageUpload
              instruction={textUploadSignature}
              execute={profilePic}
              endpoint="draft/upload/image"
              formData={{ draftId, profilePic: "" }}
              dataKey="profilePic"
              defaultValue={myApplication.partB.profilePic}
            />
          </div>
        </div>

        {/* ====================== Forwarding Letter From Concerned Division ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Forwarding Letter Form concerned division
              <span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={forwardingLetter}
              endpoint="draft/upload/forwarding-letter"
              formData={{ draftId, forwardingLetter: "" }}
              dataKey="forwardingLetter"
              fileName="Forwarding Letter"
              defaultValue={myApplication.attachments.forwardingLetter}
            />
          </div>
        </div>

        {/* ====================== Police Verification  ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Confirmation of Police Verification / Police Verification Report
              from Special Branch of Bangladesh Police
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={policeVerification}
              endpoint="draft/upload/police-verification"
              formData={{ draftId, policeVerification: "" }}
              dataKey="policeVerification"
              fileName="Police Verification File"
              defaultValue={myApplication.partB.policeVerificationFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`Police Verification ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={policeVerificationValidTill}
            defaultValue={
              myApplication.partB.policeVerificationValidTill
                ? myApplication.partB.policeVerificationValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>

        {/* ====================== Copy Of NID ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of National Id Card<span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={copyOfNID}
              endpoint="draft/upload/nid-copy"
              formData={{ draftId, copyOfNID: "" }}
              dataKey="copyOfNID"
              fileName="NID Card"
              defaultValue={myApplication.attachments.copyOfNID}
            />
          </div>
        </div>

        {/* ====================== Authorization Letter from Chairman ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Letter of job assignment by CAAB Chairman
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={natureOfDutyDescriptionFile}
              endpoint="draft/upload/duty-description"
              formData={{ draftId, dutyDescription: "" }}
              dataKey="dutyDescription"
              fileName="Duty Description"
              defaultValue={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>
        </div>

        {/* ====================== Agreement ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Agreement, in case of Contractual Employment
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={copyOfAgreement}
              endpoint="draft/upload/agreement-copy"
              formData={{ draftId, copyOfAgreement: "" }}
              dataKey="copyOfAgreement"
              fileName="Copy of Agreement"
              defaultValue={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>

        {/* ====================== Driving License ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Driving License (Where Applicable)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={drivingLicense}
              endpoint="draft/upload/driving-license"
              formData={{ draftId, drivingLicense: "" }}
              dataKey="drivingLicense"
              fileName="Copy of Driving License"
              defaultValue={myApplication.attachments.drivingLicense}
            />
          </div>
        </div>

        {/* ====================== Existing ID Card ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Existing Pass Card (In case of Renewal)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={existingPermit}
              endpoint="draft/upload/existing-permit"
              formData={{ draftId, existingPermit: "" }}
              dataKey="existingPermit"
              fileName="Copy of Existing Pass Card"
              defaultValue={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        {/* ====================== GSAT ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              GSAT Certificate
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={GSAT}
              endpoint="draft/upload/gsat"
              formData={{ draftId, gsat: "" }}
              dataKey="gsat"
              fileName="GSAT File"
              defaultValue={myApplication.partB.gsatFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`GSAT ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={gsatValidTill}
            defaultValue={
              myApplication.partB.gsatValidTill
                ? myApplication.partB.gsatValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>
      </>
    );
  };

  const AAIAP = () => {
    return (
      <>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className={styles.headerText}>
                Following are the Attachments Upload Required For This
                Application
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="p-0 ps-3">
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Upload Picture (picture Upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Forwarding Letter Form concerned division (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Confirmation of Police Verification / Police Verification
                  Report from Special Branch of Bangladesh Police (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  PV valid till (calendar date)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of NID (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Letter of job assignment by CAAB Chairman / Ministry (pdf
                  upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Agreement, in case of Contractual Employment (pdf
                  upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of driving license (Where Applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of existing permit in case of renewal (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Certificate (where applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Valid Till (calendar date)
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* ====================== Picture ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Upload Picture<span className="text-danger">*</span>
            </label>

            <ImageUpload
              instruction={textUploadSignature}
              execute={profilePic}
              endpoint="draft/upload/image"
              formData={{ draftId, profilePic: "" }}
              dataKey="profilePic"
              defaultValue={myApplication.partB.profilePic}
            />
          </div>
        </div>

        {/* ====================== Forwarding Letter From Concerned Division ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Forwarding Letter Form concerned division
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={forwardingLetter}
              endpoint="draft/upload/forwarding-letter"
              formData={{ draftId, forwardingLetter: "" }}
              dataKey="forwardingLetter"
              fileName="Forwarding Letter"
              defaultValue={myApplication.attachments.forwardingLetter}
            />
          </div>
        </div>

        {/* ====================== Police Verification  ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Confirmation of Police Verification / Police Verification Report
              from Special Branch of Bangladesh Police
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={policeVerification}
              endpoint="draft/upload/police-verification"
              formData={{ draftId, policeVerification: "" }}
              dataKey="policeVerification"
              fileName="Police Verification File"
              defaultValue={myApplication.partB.policeVerificationFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`Police Verification ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={policeVerificationValidTill}
            defaultValue={
              myApplication.partB.policeVerificationValidTill
                ? myApplication.partB.policeVerificationValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>

        {/* ====================== Copy Of NID ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of National Id Card<span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={copyOfNID}
              endpoint="draft/upload/nid-copy"
              formData={{ draftId, copyOfNID: "" }}
              dataKey="copyOfNID"
              fileName="NID Card"
              defaultValue={myApplication.attachments.copyOfNID}
            />
          </div>
        </div>

        {/* ====================== Authorization Letter from Chairman / Ministry ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Letter of job assignment by CAAB Chairman / Ministry
              <span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={natureOfDutyDescriptionFile}
              endpoint="draft/upload/duty-description"
              formData={{ draftId, dutyDescription: "" }}
              dataKey="dutyDescription"
              fileName="REF Chairman / Ministry"
              defaultValue={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>
        </div>

        {/* ====================== Agreement ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Agreement, in case of Contractual Employment
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={copyOfAgreement}
              endpoint="draft/upload/agreement-copy"
              formData={{ draftId, copyOfAgreement: "" }}
              dataKey="copyOfAgreement"
              fileName="Copy of Agreement"
              defaultValue={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>

        {/* ====================== Driving License ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Driving License (Where Applicable)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={drivingLicense}
              endpoint="draft/upload/driving-license"
              formData={{ draftId, drivingLicense: "" }}
              dataKey="drivingLicense"
              fileName="Copy of Driving License"
              defaultValue={myApplication.attachments.drivingLicense}
            />
          </div>
        </div>

        {/* ====================== Existing ID Card ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Existing Pass Card (In case of Renewal)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={existingPermit}
              endpoint="draft/upload/existing-permit"
              formData={{ draftId, existingPermit: "" }}
              dataKey="existingPermit"
              fileName="Copy of Existing Pass Card"
              defaultValue={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        {/* ====================== GSAT ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              GSAT Certificate
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={GSAT}
              endpoint="draft/upload/gsat"
              formData={{ draftId, gsat: "" }}
              dataKey="gsat"
              fileName="GSAT File"
              defaultValue={myApplication.partB.gsatFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`GSAT ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={gsatValidTill}
            defaultValue={
              myApplication.partB.gsatValidTill
                ? myApplication.partB.gsatValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>
      </>
    );
  };

  const NIAC = () => {
    return (
      <>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className={styles.headerText}>
                Following are the Attachments Upload Required For This
                Application
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="p-0 ps-3">
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Upload Picture (picture Upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Forwarding Letter Form concerned division (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Confirmation of Police Verification / Police Verification
                  Report from Special Branch of Bangladesh Police (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  PV valid till (calendar date)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of NID (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Letter of authorization as CAAB inspector from CAAB Chairman
                  (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of Agreement, in case of Contractual Employment (pdf
                  upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of driving license (Where Applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  Copy of existing permit in case of renewal (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Certificate (where applicable) (pdf upload)
                </li>
                <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
                  GSAT Valid Till (calendar date)
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <div className="mt-4 mb-2">
          <h5 className={styles.headerText}>
            Following are the Attachments Upload Required For This Application{" "}
          </h5>
          <ul className="p-0 ps-3">
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Upload Picture (picture Upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Forwarding Letter Form concerned division (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Confirmation of Police Verification / Police Verification Report
              from Special Branch of Bangladesh Police (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              PV valid till (calendar date)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of NID (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Letter of authorization as CAAB inspector from CAAB Chairman (pdf
              upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of Agreement, in case of Contractual Employment (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of driving license (Where Applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              Copy of existing permit in case of renewal (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Certificate (where applicable) (pdf upload)
            </li>
            <li className={`pb-1 p-0 m-0 border-bottom-0 ${styles.text}`}>
              GSAT Valid Till (calendar date)
            </li>
          </ul>
        </div> */}

        {/* ====================== Picture ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Upload Picture<span className="text-danger">*</span>
            </label>

            <ImageUpload
              instruction={textUploadSignature}
              execute={profilePic}
              endpoint="draft/upload/image"
              formData={{ draftId, profilePic: "" }}
              dataKey="profilePic"
              defaultValue={myApplication.partB.profilePic}
            />
          </div>
        </div>

        {/* ====================== Forwarding Letter From Concerned Division ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Forwarding Letter Form concerned division
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={forwardingLetter}
              endpoint="draft/upload/forwarding-letter"
              formData={{ draftId, forwardingLetter: "" }}
              dataKey="forwardingLetter"
              fileName="Forwarding Letter"
              defaultValue={myApplication.attachments.forwardingLetter}
            />
          </div>
        </div>

        {/* ====================== Police Verification  ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Confirmation of Police Verification / Police Verification Report
              from Special Branch of Bangladesh Police
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={policeVerification}
              endpoint="draft/upload/police-verification"
              formData={{ draftId, policeVerification: "" }}
              dataKey="policeVerification"
              fileName="Police Verification File"
              defaultValue={myApplication.partB.policeVerificationFile}
            />
          </div>
        </div>

        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`Police Verification ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={policeVerificationValidTill}
            defaultValue={
              myApplication.partB.policeVerificationValidTill
                ? myApplication.partB.policeVerificationValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>

        {/* ====================== Copy Of NID ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of National Id Card<span className="text-danger">*</span>
            </label>

            <PDFUpload
              instruction={textUpload}
              execute={copyOfNID}
              endpoint="draft/upload/nid-copy"
              formData={{ draftId, copyOfNID: "" }}
              dataKey="copyOfNID"
              fileName="NID Card"
              defaultValue={myApplication.attachments.copyOfNID}
            />
          </div>
        </div>

        {/* ====================== Authorization Letter from Chairman ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Letter of authorization as CAAB inspector from CAAB Chairman
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={typeOfAuthorizationSafetyFile}
              endpoint="draft/upload/authorization-safety"
              formData={{ draftId, authorizationSafety: "" }}
              dataKey="authorizationSafety"
              fileName="Authorization of Safety Inspector"
              defaultValue={myApplication.partC.typeOfAuthorizationSafetyFile}
            />
          </div>
        </div>

        {/* ====================== Agreement ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Agreement, in case of Contractual Employment
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={copyOfAgreement}
              endpoint="draft/upload/agreement-copy"
              formData={{ draftId, copyOfAgreement: "" }}
              dataKey="copyOfAgreement"
              fileName="Copy of Agreement"
              defaultValue={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>

        {/* ====================== Driving License ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Driving License (Where Applicable)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={drivingLicense}
              endpoint="draft/upload/driving-license"
              formData={{ draftId, drivingLicense: "" }}
              dataKey="drivingLicense"
              fileName="Copy of Driving License"
              defaultValue={myApplication.attachments.drivingLicense}
            />
          </div>
        </div>

        {/* ====================== Existing ID Card ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              Copy of Existing Pass Card (In case of Renewal)
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={existingPermit}
              endpoint="draft/upload/existing-permit"
              formData={{ draftId, existingPermit: "" }}
              dataKey="existingPermit"
              fileName="Copy of Existing Pass Card"
              defaultValue={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        {/* ====================== GSAT ====================== */}
        <div className="col-12 mt-3">
          <div className={styles.signArea}>
            <label className={`form-label ${styles.label}`}>
              GSAT Certificate
            </label>
            <PDFUpload
              instruction={textUpload}
              execute={GSAT}
              endpoint="draft/upload/gsat"
              formData={{ draftId, gsat: "" }}
              dataKey="gsat"
              fileName="GSAT File"
              defaultValue={myApplication.partB.gsatFile}
            />
          </div>
        </div>
        <div className={`col-12 ${styles.validity}`}>
          <DateInput
            label={`GSAT ${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={gsatValidTill}
            defaultValue={
              myApplication.partB.gsatValidTill
                ? myApplication.partB.gsatValidTill
                : ""
            }
            isMandatory={false}
          />
        </div>
      </>
    );
  };

  const AssetContainer = ({ fieldLabel, assetUrl, assetName }) => {
    return (
      <>
        <label className={`form-label ${styles.uploadPictureFieldLabel}`}>
          {fieldLabel}
        </label>

        <div
          className={styles.fileUploadAsset}
          onClick={() => (assetUrl ? window.open(assetUrl) : null)}
        >
          <FontAwesomeIcon
            className={`mx-3 ${styles.iconArchive}`}
            icon={faFileArchive}
          />
          <div className={styles.assetFileName}>
            {assetUrl ? assetName : "==== NOT UPLOADED ===="}
          </div>
          {assetUrl && (
            <FontAwesomeIcon
              className={`mx-3 ${styles.iconDownload}`}
              icon={faCloudDownloadAlt}
            />
          )}
        </div>
      </>
    );
  };

  const AssetNIAC = () => {
    return (
      <div className={styles.assetContainer}>
        <div className={`${styles.leftPortion} col-6  pb-1`}>
          <div className={styles.pictureField}>
            <label className={`form-label ${styles.uploadPictureFieldLabel}`}>
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className={`${styles.imageStyling} img-fluid img-thumbnail rounded`}
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className={`${styles.rightPortion} col-6  pb-1`}>
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Authorization as CAAB Inspector form CAAB Chairman"
              assetName="Letter of Authorization"
              assetUrl={myApplication.partC.typeOfAuthorizationSafetyFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetNAP = () => {
    return (
      <div className={styles.assetContainer}>
        <div className={`${styles.leftPortion} col-6  pb-1`}>
          <div className={styles.pictureField}>
            <label className={`form-label ${styles.uploadPictureFieldLabel}`}>
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className={`${styles.imageStyling} img-fluid img-thumbnail rounded`}
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className={`${styles.rightPortion} col-6  pb-1`}>
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Approved Office Order By CAAB Chairman"
              assetName="Approved Office Order"
              assetUrl={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Job Assignment form CAAB Chairman"
              assetName="Letter of Job Assignment"
              assetUrl={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetAAIAP = () => {
    return (
      <div className={styles.assetContainer}>
        <div className={`${styles.leftPortion} col-6  pb-1`}>
          <div className={styles.pictureField}>
            <label className={`form-label ${styles.uploadPictureFieldLabel}`}>
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className={`${styles.imageStyling} img-fluid img-thumbnail rounded`}
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className={`${styles.rightPortion} col-6  pb-1`}>
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Approved Office Order By CAAB Chairman / Ministry"
              assetName="Approved Office Order"
              assetUrl={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Job Assignment form CAAB Chairman"
              assetName="Letter of Job Assignment"
              assetUrl={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetCMAP = () => {
    return (
      <div className={styles.assetContainer}>
        <div className={`${styles.leftPortion} col-6  pb-1`}>
          <div className={styles.pictureField}>
            <label className={`form-label ${styles.uploadPictureFieldLabel}`}>
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className={`${styles.imageStyling} img-fluid img-thumbnail rounded`}
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Certificate from FSR Division"
              assetName="Certificate from FSR Division"
              assetUrl={myApplication.attachments.certificateFSR}
            />
          </div>
        </div>

        <div className={`${styles.rightPortion} col-6  pb-1`}>
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter from Employer"
              assetName="Forwarding Letter from Employer"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of National Id"
              assetName="Copy of National Id"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Crew Id"
              assetName="Copy of Crew Id"
              assetUrl={myApplication.attachments.employeeId}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Crew License"
              assetName="Copy of Crew License"
              assetUrl={myApplication.attachments.employeeLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetASP = () => {
    return (
      <>
        {/* =================== For Govt Employee =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP === "GovtEmp" && (
          <div className={styles.assetContainer}>
            <div className={`${styles.leftPortion} col-6  pb-1`}>
              <div className={styles.pictureField}>
                <label
                  className={`form-label ${styles.uploadPictureFieldLabel}`}
                >
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className={`${styles.imageStyling} img-fluid img-thumbnail rounded`}
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Driving License"
                  assetName="Copy of Driving License"
                  assetUrl={myApplication.attachments.drivingLicense}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>
            </div>

            <div className={`${styles.rightPortion} col-6  pb-1`}>
              <AssetContainer
                fieldLabel="Forwarding letter from the employer authorized official with
                confirmation statement that the initial background checks and the
                police verification checks has been conducted on the person"
                assetName="Forwarding letter from employer"
                assetUrl={myApplication.attachments.forwardingLetter}
              />

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of NID"
                  assetName="Copy of NID"
                  assetUrl={myApplication.attachments.copyOfNID}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Employee Id"
                  assetName="Copy of Employee Id"
                  assetUrl={myApplication.attachments.employeeId}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Posting order/ office order for the job assignment at airport"
                  assetName="Posting order/ office order"
                  assetUrl={myApplication.attachments.officeOrder}
                />
              </div>
            </div>
          </div>
        )}

        {/* =================== For Non Govt Employee =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP ===
          "NonGovtEmp" && (
          <div className={styles.assetContainer}>
            <div className={`${styles.leftPortion} col-6  pb-1`}>
              <div className={styles.pictureField}>
                <label
                  className={`form-label ${styles.uploadPictureFieldLabel}`}
                >
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className={`${styles.imageStyling} img-fluid img-thumbnail rounded`}
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Driving License"
                  assetName="Copy of Driving License"
                  assetUrl={myApplication.attachments.drivingLicense}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Work Order"
                  assetName="Copy of Work Order"
                  assetUrl={myApplication.attachments.copyOfWorkOrder}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Employee Id"
                  assetName="Copy of Employee Id"
                  assetUrl={myApplication.attachments.employeeId}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>
            </div>

            <div className={`${styles.rightPortion} col-6  pb-1`}>
              <AssetContainer
                fieldLabel={`${t(
                  "NEWAPPLICATION.confirmApplication.policeVerificationFile"
                )}`}
                assetName="Police Verification"
                assetUrl={myApplication.partB.policeVerificationFile}
              />

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="policeVerificationValidTill"
                  disable
                  defaultValue={myApplication.partB.policeVerificationValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Forwarding letter from the employer"
                  assetName="Forwarding letter from employer"
                  assetUrl={myApplication.attachments.forwardingLetter}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of NID"
                  assetName="Copy of NID"
                  assetUrl={myApplication.attachments.copyOfNID}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Complete Background Check By Employer"
                  assetName="Background Check"
                  assetUrl={myApplication.attachments.backgroundCheck}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Valid AOC/ Permission and/ or Flight Schedule that is approved by CAAB"
                  assetName="Valid AOC/ Permission and/ or Flight Schedule"
                  assetUrl={myApplication.attachments.validAOC}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Agreement with CAAB or other Organization "
                  assetName="Agreement with CAAB or other Organization"
                  assetUrl={myApplication.attachments.copyOfAgreement}
                />
              </div>
            </div>
          </div>
        )}

        {/* =================== For Foreigners =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP ===
          "Foreigners" && (
          <div className={styles.assetContainer}>
            <div className={`${styles.leftPortion} col-6  pb-1`}>
              <div className={styles.pictureField}>
                <label
                  className={`form-label ${styles.uploadPictureFieldLabel}`}
                >
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className={`${styles.imageStyling} img-fluid img-thumbnail rounded`}
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation from Bangladesh Investment Development Agency
                (BIDA)"
                  assetName="Recommendation from BIDA"
                  assetUrl={myApplication.attachments.recommendationBIDA}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Visa"
                  assetName="Copy of Visa"
                  assetUrl={myApplication.attachments.copyOfVisa}
                />
              </div>
            </div>

            <div className={`${styles.rightPortion} col-6  pb-1`}>
              <AssetContainer
                fieldLabel="Copy of Passport"
                assetName="Copy of Passport"
                assetUrl={myApplication.attachments.copyOfPassport}
              />

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of note verbale issued by the mission"
                  assetName="Copy of note verbale"
                  assetUrl={myApplication.attachments.noteVerbale}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation of concerned ministry or authority"
                  assetName="Recommendation of ministry"
                  assetUrl={myApplication.attachments.recommendationOfMinistry}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Security Clearance form Ministry of Home Affairs"
                  assetName="Security Clearance MOHA"
                  assetUrl={myApplication.attachments.securityClearanceMOHA}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation of Ministry of Foreign affairs by endorsing the
              application form"
                  assetName="Recommendation of Ministry of Foreign affairs"
                  assetUrl={myApplication.attachments.recommendationMOFA}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    // console.log(myApplication.attachments.selectedAttachmentGroupASP);
    if (card === "ASP")
      setUploadCategory(myApplication.attachments.selectedAttachmentGroupASP);

    // return () => {
    //   second
    // }
  }, []);

  return (
    <>
      {mode === "write" && (
        <>
          {card === "NIAC" && <NIAC />}
          {card === "NAP" && <NAP />}
          {card === "CMAP" && <CMAP />}
          {card === "ASP" && <ASP />}
          {card === "AAIAP" && <AAIAP />}
        </>
      )}

      {mode === "read" && (
        <>
          {card === "NIAC" && <AssetNIAC />}
          {card === "NAP" && <AssetNAP />}
          {card === "CMAP" && <AssetCMAP />}
          {card === "ASP" && <AssetASP />}
          {card === "AAIAP" && <AssetAAIAP />}
        </>
      )}
    </>
  );
}

export default Attachments;
