import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationDataPartA,
  changeActiveApplicationPart,
} from "../../../../../../redux/actions";

import FormTitle from "../../Utilities/FormTitle/FormTitle";
import Stepper from "../../Utilities/Stepper/Stepper";
import StepperShift from "../../Utilities/StepperShift/StepperShift";
import Selector from "../../../../../../Utilities/Selector/Selector";
import InputField from "../../../../../../Utilities/InputField/InputField";
import AuthorizationArea from "../../../../../../Utilities/AuthorizationArea/AuthorizationArea";
import DateInput from "../../../../../../Utilities/DateInput/DateInput";

import { useTranslation } from "react-i18next";

const TypeIssue = () => {
  const [checkedValue, setCheckedValue] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myCurrentStatesIssueType = useSelector(
    (state) => state.newApplicationData.newApplication.partA.issueType
  );

  const myCurrentStates = useSelector(
    (state) => state.newApplicationData.newApplication.partA
  );

  const availableSites = useSelector(
    (state) => state.newApplicationData.newApplication.partA.availableSites
  );

  let allSites = [
    {
      label: `Select The Required Airport`,
      value: "",
    },
    // {
    //   label: `HSIA`,
    //   value: "1",
    // },
    // {
    //   label: `OIA`,
    //   value: "2",
    // },
  ];
  const formattedSiteValue = availableSites.map((site) => ({
    label: `${site.name} (${site.acronym})`,
    value: site.id,
  }));
  allSites = [...allSites, ...formattedSiteValue];

  const CmapCategories = [
    {
      label: `Select Previous CMAP Category`,
      value: "",
    },
    {
      label: `Pilot (Schedule Passenger Operation)`,
      value: "P-SPO",
    },
    {
      label: `Pilot (Helicopter)`,
      value: "P-H",
    },
    {
      label: `Pilot (Flight Cargo)`,
      value: "P-FC",
    },
    {
      label: `Cabin Crew (Schedule Passenger Operation)`,
      value: "CC-SPO",
    },
    {
      label: `Flying School Instructor (FSI)`,
      value: "FSI",
    },
    {
      label: `Flying School Trainee (FST)`,
      value: "FST",
    },
  ];

  useEffect(() => {
    setCheckedValue(myCurrentStatesIssueType);
    dispatch(changeActiveApplicationPart("Part A"));
  }, [myCurrentStatesIssueType]);

  const issueTypes = [
    {
      label: `${t("NEWAPPLICATION.typeIssue.typeOfIssue.option.label")}`,
      value: "",
    },
    {
      label: `${t("NEWAPPLICATION.typeIssue.typeOfIssue.option.initial")}`,
      value: "Initial",
    },
    {
      label: `${t("NEWAPPLICATION.typeIssue.typeOfIssue.option.expired")}`,
      value: "Re-issue (Expired)",
    },
    {
      label: `${t("NEWAPPLICATION.typeIssue.typeOfIssue.option.lost")}`,
      value: "Re-issue (Lost /Stolen)",
    },
    // {
    //   label: `${t("NEWAPPLICATION.typeIssue.typeOfIssue.option.company")}`,
    //   value: "Re-issue (Company Change)",
    // },
    {
      label: `${t("NEWAPPLICATION.typeIssue.typeOfIssue.option.designation")}`,
      value: "Re-issue (Designation Change)",
    },
  ];

  const cardTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.typeIssueSelected.colorOfCard.option.label"
      )}`,
      value: "",
    },
    {
      label: `${t("NEWAPPLICATION.typeIssueSelected.colorOfCard.option.red")}`,
      value: "Red",
    },
    {
      label: `${t(
        "NEWAPPLICATION.typeIssueSelected.colorOfCard.option.green"
      )}`,
      value: "Green",
    },
    {
      label: `${t("NEWAPPLICATION.typeIssueSelected.colorOfCard.option.blue")}`,
      value: "Blue",
    },
    {
      label: `${t(
        "NEWAPPLICATION.typeIssueSelected.colorOfCard.option.yellow"
      )}`,
      value: "Yellow",
    },
  ];

  const issueType = (selectedData) => {
    setCheckedValue(selectedData);
    dispatch(
      applicationDataPartA({
        issueType: selectedData,
        colorOfPass: "",
        gdNumber: "",
        gdEntry: "",
        psName: "",
        // existingPassNumber: "",
        A: false,
        B: false,
        C: false,
        D: false,
        E: false,
        T: false,
        R: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
      })
    );
  };

  const colorOfPass = (selectedData) => {
    dispatch(
      applicationDataPartA({
        colorOfPass: selectedData,
      })
    );
  };

  const existingCMAPcategory = (selectedData) => {
    dispatch(
      applicationDataPartA({
        existingCMAPcategory: selectedData,
      })
    );
  };

  const siteId = (selectedData) => {
    dispatch(
      applicationDataPartA({
        siteId: selectedData,
      })
    );
  };

  const existingPassNumber = (inputData) => {
    dispatch(
      applicationDataPartA({
        existingPassNumber: inputData,
      })
    );
  };

  const gdNumber = (inputData) => {
    dispatch(
      applicationDataPartA({
        gdNumber: inputData,
      })
    );
  };

  const gdEntry = (inputData) => {
    dispatch(
      applicationDataPartA({
        gdEntry: inputData,
      })
    );
  };

  const psName = (inputData) => {
    dispatch(
      applicationDataPartA({
        psName: inputData,
      })
    );
  };

  // const nextStepDecider =
  //   myCurrentStates === "Initial" ? (
  //     <StepperShift increaseBy={2} showDraft decreaseBy={2} lastCompleted="A" />
  //   ) : (
  //     <StepperShift decreaseBy={2} />
  //   );

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.typeIssue.title")}`}
        subTitle={`${t("NEWAPPLICATION.typeIssue.subTitle")}`}
      />
      <Stepper />
      <div className="row">
        {myCurrentStates.passType === "ASP" && (
          <div className="col-12">
            <Selector
              label="Select Airport"
              listItem={allSites}
              defaultValue=""
              execute={siteId}
            />
          </div>
        )}
        <div className="col-12 mt-3">
          <Selector
            label={`${t("NEWAPPLICATION.typeIssue.typeOfIssue.label")}`}
            placeholder={`${t(
              "NEWAPPLICATION.typeIssue.typeOfIssue.placeholder"
            )}`}
            listItem={issueTypes}
            defaultValue={
              myCurrentStatesIssueType ? myCurrentStatesIssueType : ""
            }
            execute={issueType}
          />
        </div>

        {checkedValue && checkedValue !== "Initial" && (
          <>
            <div className="col-12 mt-3">
              <InputField
                defaultValue={
                  myCurrentStates.existingPassNumber
                    ? myCurrentStates.existingPassNumber
                    : ""
                }
                execute={existingPassNumber}
                type="text"
                label={`Existing AvSecID`}
                name="pass_number"
                placeholder={`Enter AvSecID Number`}
              />
            </div>
            {/*  
            {myCurrentStates.passType === "CMAP" && (
              <div className="col-12 mt-3">
                <Selector
                  label="Existing CMAP Type"
                  placeholder="Select One"
                  listItem={CmapCategories}
                  defaultValue={
                    myCurrentStates.existingCMAPcategory
                      ? myCurrentStates.existingCMAPcategory
                      : ""
                  }
                  execute={existingCMAPcategory}
                />
               
              </div>
            )}

            {myCurrentStates.passType === "ASP" && (
              <div className="col-12 mt-3">
                <Selector
                  label={`${t(
                    "NEWAPPLICATION.typeIssueSelected.colorOfCard.label"
                  )}`}
                  placeholder={`${t(
                    "NEWAPPLICATION.typeIssueSelected.colorOfCard.placeholder"
                  )}`}
                  listItem={cardTypes}
                  defaultValue={
                    myCurrentStates.colorOfPass
                      ? myCurrentStates.colorOfPass
                      : ""
                  }
                  execute={colorOfPass}
                />
              
              </div>
            )}

            {(myCurrentStates.passType === "ASP" ||
              myCurrentStates.passType === "NAP") && (
              <div className="col-12 mt-3">
                <AuthorizationArea checkboxes={myCurrentStates} />
              </div>
            )}*/}

            {checkedValue === "Re-issue (Lost /Stolen)" && (
              <>
                <div className="col-12 mt-3">
                  <InputField
                    defaultValue={
                      myCurrentStates.gdNumber ? myCurrentStates.gdNumber : ""
                    }
                    execute={gdNumber}
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.typeIssueSelected.gdNumber.label"
                    )}`}
                    name="gd_number"
                    placeholder={`${t(
                      "NEWAPPLICATION.typeIssueSelected.gdNumber.placeholder"
                    )}`}
                  />
                </div>
                <div className="col-12 mt-3">
                  <DateInput
                    label={`${t(
                      "NEWAPPLICATION.typeIssueSelected.dateOfEntry.label"
                    )}`}
                    execute={gdEntry}
                    defaultValue={
                      myCurrentStates.gdEntry ? myCurrentStates.gdEntry : ""
                    }
                  />
                </div>
                <div className="col-12 mt-3">
                  <InputField
                    defaultValue={
                      myCurrentStates.psName ? myCurrentStates.psName : ""
                    }
                    execute={psName}
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.typeIssueSelected.policeStationName.label"
                    )}`}
                    name="police_station_name"
                    placeholder={`${t(
                      "NEWAPPLICATION.typeIssueSelected.policeStationName.placeholder"
                    )}`}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
      {checkedValue ? (
        // nextStepDecider
        <StepperShift
          showDraft
          lastCompleted="A"
          endPoint={checkedValue !== "Initial" ? "reissue" : "create"}
          increaseBy={checkedValue !== "Initial" ? 7 : 1}
        />
      ) : (
        <StepperShift nextDisabled />
      )}
    </>
  );
};

export default TypeIssue;
